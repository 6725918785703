export const secToMin = sec => {
  const min = Math.floor(sec / 60)
    .toString()
    .padStart(2, '');
  const remainder = Math.floor(sec % 60)
    .toString()
    .padStart(2, '');
  return `${min}:${remainder}`;
};

export const capitalizeFirstLetter = (str: string) =>
  str[0].toUpperCase() + str.substr(1, str.length);

export const getRandomNumber = (min: number, max: number) =>
  Math.round(Math.random() * (max - min) + min);
