import styled from 'styled-components';

export const PrimaryButton = styled.button`
  padding: 0.8rem 1.4rem;
  border-radius: 12px;
  background-color: rgba(244, 147, 51, 0.9);
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  :hover {
    background-color: rgba(244, 147, 51, 1);
    transform: translateY(-3px);
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    padding: 0.8rem 1.2rem;
  }
`;
