import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import firebaseConfig from './config.json';
import { CHAMPION_NOT_FOUND } from 'constants/error';
import { ChampId, Champion } from 'types';
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
firebase.analytics();

const CONFIG = {
  DISLIKE: false,
  LIKE: true
};
class Fire {
  private firestore: firebase.firestore.Firestore;

  constructor() {
    this.firestore = app.firestore();
  }

  getChampData = (champId: ChampId) =>
    this.firestore
      .doc(`/lol/${champId}`)
      .get()
      .then(docSnapshot => {
        if (docSnapshot.exists) return docSnapshot.data();
        throw new Error(CHAMPION_NOT_FOUND);
      });

  updateVoting = (isLike: boolean) => (champId: string, songId: string) => {
    const champDoc = this.firestore.doc(`/lol/${champId}`);
    return champDoc.get().then(doc => {
      const { votingPlaylist } = doc.data() as Champion;
      const { likes, dislikes } = votingPlaylist[songId];
      const newSong = {
        [songId]: {
          dislikes: dislikes + +!isLike,
          likes: likes + +isLike
        }
      };

      return champDoc.update({
        votingPlaylist: {
          ...votingPlaylist,
          ...newSong
        }
      });
    });
  };

  upvote = (champId, songId) => this.updateVoting(CONFIG.LIKE)(champId, songId);

  downvote = (champId, songId) =>
    this.updateVoting(CONFIG.DISLIKE)(champId, songId);

  submitSong = (songId, champId) =>
    this.getChampData(champId).then(champsData =>
      this.firestore
        .collection('lol')
        .doc(champId)
        .update({
          votingPlaylist: {
            ...(champsData as Champion).votingPlaylist,
            [songId]: { likes: 0, dislikes: 0 }
          }
        })
    );
}

export const fire = new Fire();
