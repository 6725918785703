import PropTypes from 'prop-types';
import styled from 'styled-components';

const ChampImg = styled.img`
  width: 100%;
  height: auto;
`;

const ChampImgContainer = styled.div<{ size: number }>`
  padding: 0.2rem;
  width: ${({ size }) => `${size}rem`};
`;

export const ChampionIcon = ({ patchVersion, champId, size }) => (
  <ChampImgContainer size={size}>
    <ChampImg
      src={`https://ddragon.leagueoflegends.com/cdn/${patchVersion}/img/champion/${champId}.png`}
      alt={champId}
    />
  </ChampImgContainer>
);

ChampionIcon.defaultProps = {
  size: 2,
  patchVersion: '10.1.1'
};

ChampionIcon.propTypes = {
  patchVersion: PropTypes.string,
  champId: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
