import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { RootState } from 'types/RootState';
import { favouritesReducer } from './favourites';
import { votingReducer } from './voting';

const rootReducer = combineReducers<RootState>({
  favourites: favouritesReducer,
  voting: votingReducer
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    // https://redux-toolkit.js.org/api/getDefaultMiddleware#intended-usage
    serializableCheck: {
      ignoredActions: ['persist/PERSIST']
    }
  }),
  enhancers: [createReduxEnhancer()]
});
export const persistor = persistStore(store);
