import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { ChampionIcon } from 'components/common/ChampionIcon';
import { FavoriteChampionsContext } from 'components/context/FavoriteChampionsContext';

export const ChampionsBar = styled.div`
  width: 60%;
  color: #a1a1a1;
  padding: 0.5rem;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    width: 80%;
    padding: 1rem;
  }
`;

export const FavoriteChampionBar = ({ patchVersion }) => {
  const { favorites } = useContext(FavoriteChampionsContext);

  return (
    <ChampionsBar>
      {favorites && favorites.length
        ? favorites.map(champId => (
            <Link key={champId} to={`/lol/${champId}`}>
              <ChampionIcon champId={champId} patchVersion={patchVersion} />
            </Link>
          ))
        : 'Your Fav champs will go here, go on add some!'}
    </ChampionsBar>
  );
};

FavoriteChampionBar.defaultProps = {
  patchVersion: '10.1.1'
};

FavoriteChampionBar.propTypes = {
  patchVersion: PropTypes.string
};
