import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { Link, Route, useParams, useRouteMatch } from 'react-router-dom';
import { CHAMPION_NOT_FOUND } from 'constants/error';
import { fire } from 'fire';
import { ChampionLoadingImage, Loading } from 'components/common';
import { PlaylistPlayer } from './PlaylistPlayer';
import { Container, ControlsContainer, TextRectangle } from './styles';

import PlayIcon from 'static/svgs/play.svg';
import { ChampId, Champion } from 'types';

const Controls = ({ id, loading, error, mainPlaylist, votingPlaylist }) => {
  const { path, url } = useRouteMatch();
  if (loading) return <Loading message="Fetching playlists" />;
  if (error) return error.message;

  return (
    <ControlsContainer>
      <Route exact path={path}>
        <Link to={`${url}/main`}>
          <TextRectangle main>
            <div>
              <h2>Main Playlist</h2>
              <PlayIcon />
            </div>
            <p>Listen to the champion most popular songs</p>
          </TextRectangle>
        </Link>
        <Link to={`${url}/voting`}>
          <TextRectangle>
            <div>
              <h2>Voting Playlist</h2>
              <PlayIcon />
            </div>
            <p>Listen to suggested songs by other users</p>
          </TextRectangle>
        </Link>
      </Route>
      <Route
        path={`${path}/:playlistType`}
        render={() => (
          <PlaylistPlayer
            mainPlaylist={mainPlaylist}
            votingPlaylist={votingPlaylist}
            id={id}
          />
        )}
      />
    </ControlsContainer>
  );
};

Controls.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  loading: PropTypes.bool.isRequired,
  mainPlaylist: PropTypes.arrayOf(PropTypes.string).isRequired,
  votingPlaylist: PropTypes.shape({
    likes: PropTypes.number,
    dislikes: PropTypes.number
  }).isRequired,
  id: PropTypes.string.isRequired
};

const Memoized = memo(Controls);

const emptyChampionState: Champion = {
  id: '',
  name: '',
  mainPlaylist: [],
  votingPlaylist: {}
};

export const ChampionPage = () => {
  const { id } = useParams<{ id: ChampId }>();
  const [data, setData] = useState<Champion>(emptyChampionState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setLoading(true);
    fire
      .getChampData(id)
      .then(champData => setData(champData as Champion))
      .then(() => setLoading(false))
      .catch(err => {
        if (err.message === CHAMPION_NOT_FOUND) setError(err);
        setLoading(false);
      });
  }, [id]);

  const { mainPlaylist, votingPlaylist } = data;

  return (
    <Container>
      <ChampionLoadingImage champId={id} />
      <Memoized
        id={id}
        mainPlaylist={mainPlaylist}
        votingPlaylist={votingPlaylist}
        loading={loading}
        error={error}
      />
    </Container>
  );
};
