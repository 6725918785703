import { FavoriteChampionBar, Header, ShareSide } from 'components/common';
import {
  ChampionPage,
  ClientError,
  Contact,
  Landing
} from 'components/containers';
import { FavoriteChampionsContext } from 'components/context/FavoriteChampionsContext';
import { useFavChamps, usePatchVersion } from 'components/hooks';
import { Route, Switch } from 'react-router-dom';

export const App = () => {
  const patchVersion = usePatchVersion();
  const { favorites, toggleFavoriteChampion } = useFavChamps();

  return (
    <div className="no-scroll">
      <Header />
      <Route path="/(|contact)">
        <ShareSide />
      </Route>
      <FavoriteChampionsContext.Provider
        value={{ favorites, toggleFavoriteChampion }}
      >
        <Switch>
          <Route exact path="/">
            <Landing patchVersion={patchVersion} />
          </Route>
          <Route path="/lol/:id">
            <ChampionPage />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="*">
            <ClientError />
          </Route>
        </Switch>
        <FavoriteChampionBar patchVersion={patchVersion} />
      </FavoriteChampionsContext.Provider>
    </div>
  );
};
