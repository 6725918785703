import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChampId } from 'types';
import { FavouritesState } from './types';

const initialState: FavouritesState = {
  entities: []
};

const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    addToFav(state, action: PayloadAction<ChampId>) {
      if (state.entities.find(id => id === action.payload)) return;
      state.entities.push(action.payload);
    },
    removeFav(state, action: PayloadAction<ChampId>) {
      state.entities = state.entities.filter(id => id !== action.payload);
    }
  }
});

const { reducer, actions } = favouritesSlice;

export { reducer as favouritesReducer };
export { actions as favouritesActions };
