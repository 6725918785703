import { FC } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { PrimaryButton } from 'components/common/PrimaryButton';
import moodLogo from 'assets/mood-logo.png';

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  margin-top: 3.5rem;
`;

const ImageWrapper = styled.img`
  height: 3rem;

  @media (max-width: 600px) {
    height: 2.5rem;
  }
`;

export const Header: FC = () => {
  const history = useHistory<never>();
  const handleClick = () => {
    history.push('/contact');
  };
  return (
    <HeaderWrapper>
      <Link to="/">
        <ImageWrapper src={moodLogo} alt="Mood" />
      </Link>
      <PrimaryButton onClick={handleClick}>Contact Us</PrimaryButton>
    </HeaderWrapper>
  );
};
