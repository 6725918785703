import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components';

import spinningWheel from 'assets/spinning-wheel.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 60vh;
`;

const LoadingSpinner = styled.img`
  display: block;
  height: 8rem;
  margin: 2rem auto;
  animation: ${rotate} 2s linear infinite;
`;

export const Loading = ({ message = '' }) => (
  <Wrapper>
    <h2>{message}</h2>
    <LoadingSpinner src={spinningWheel} alt="spinning wheel" />
  </Wrapper>
);

Loading.propTypes = {
  message: PropTypes.string
};
