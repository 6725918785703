import { useState, useEffect } from 'react';

export const usePatchVersion = () => {
  const [patchVersion, setPatchVersion] = useState('10.1.1');

  useEffect(() => {
    const fetchData = async () => {
      const versionsEndPoint =
        'https://ddragon.leagueoflegends.com/api/versions.json';
      const [lastVersion] = await (await fetch(versionsEndPoint)).json();

      setPatchVersion(lastVersion);
    };
    fetchData();
  }, []);

  return patchVersion;
};
