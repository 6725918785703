/* eslint-disable react/jsx-props-no-spreading */
export const DislikeIcon = props => (
  <svg fill="none" height={26} viewBox="0 0 28 26" width={28} {...props}>
    <path
      clipRule="evenodd"
      d="M17.75.5H6.5a2.483 2.483 0 00-2.3 1.525L.425 10.838a2.47 2.47 0 00-.175.912v2.5c0 1.375 1.125 2.5 2.5 2.5h7.887L9.45 22.462l-.037.4c0 .513.212.988.55 1.326l1.325 1.312 8.237-8.238c.45-.45.725-1.075.725-1.762V3c0-1.375-1.125-2.5-2.5-2.5zm0 15l-5.425 5.425L14 14.25H2.75v-2.5L6.5 3h11.25v12.5zm10-15h-5v15h5V.5z"
      fill="#E08932"
      fillRule="evenodd"
    />
  </svg>
);
