import styled from 'styled-components';

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SearchBox = styled.div`
  width: 70%;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  border-radius: 0.6rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: 600px) {
    width: 85%;
  }
`;

export const Image = styled.img`
  display: block;
`;

export const Input = styled.input`
  background-color: transparent;
  font-size: 1.4rem;
  border: none;
  outline: none;
  padding: 0.8rem 0.5rem;
  width: 100%;
  ::placeholder {
    color: rgba(#242424, 0.6);
    text-transform: capitalize;
  }
`;

export const ChampionsList = styled.ul`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 0;
  list-style-type: none;
`;

export const SingleChampion = styled.li<{ selected: boolean }>`
  background-color: ${props => (props.selected ? '#ccc' : '#e7e9e9')};
  display: flex;
  padding: 0.4rem 1rem;
  color: #242424;
  font-size: 0.9rem;
  align-items: center;
  transition: all 0.2s;
  :hover {
    background-color: #ccc;
  }

  @media (max-width: 1024px) {
    padding: 1rem;
  }
`;

export const ChampionName = styled.p`
  margin-left: 0.5rem;
  font-size: 1.2rem;
`;

export const DesiredName = styled.span`
  font-weight: 700;
`;
