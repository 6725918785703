import styled from 'styled-components';

export const LandingWrapper = styled.div`
  width: 60%;
  height: 60vh;
  margin: 0 auto;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    width: 80%;
    height: 50vh;
    text-align: center;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 350px) {
    height: 65vh;
  }
`;

export const MainHeading = styled.h1`
  font-size: 2rem;
  margin: 2rem;
  text-align: center;
`;

export const AppName = styled.span`
  color: #e08932;
`;

export const Paragraph = styled.p`
  color: #f3f3f3;
  font-size: 1.3rem;
  text-align: center;
  width: 60%;
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
  @media (max-width: 1024px) {
    width: 70%;
  }
`;

export const FixedText = styled(Paragraph)`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
`;

export const RandomText = styled.span`
  margin: 0.4rem 1rem;
  @media (max-width: 600px) {
    margin: 1rem 0.5rem;
  }
`;
