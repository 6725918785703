import { createAsyncThunk } from '@reduxjs/toolkit';
import { fire } from 'fire';
import { ChampId, VideoId } from 'types';

const upvoteSong = createAsyncThunk<
  VideoId,
  { songId: VideoId; champId: ChampId }
>('voting/upvoteSong', async ({ champId, songId: songId }) => {
  await fire.upvote(champId, songId);
  return songId;
});

const downvoteSong = createAsyncThunk<
  VideoId,
  { songId: VideoId; champId: ChampId }
>('voting/downvoteSong', async ({ champId, songId }) => {
  await fire.downvote(champId, songId);
  return songId;
});

export const VotingThunks = { upvoteSong, downvoteSong };
