import styled from 'styled-components';

export const PlayerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(226, 139, 52, 0.8);
  border-radius: 12px;
  padding: 30px;
`;

export const Text = styled.p`
  font-family: Open Sans;
  color: #ffffff;
  display: inline;
`;

export const PlaylistControls = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-evenly;
`;
export const Icon = styled.img`
  cursor: pointer;
`;

export const YoutubeEmbed = styled.div`
  z-index: -1;
`;

export const SongControls = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

export const TimeControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
`;

export const VolumeControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
`;

export const Slider = styled.input`
  height: 8.4px;
  width: 50%;
  -webkit-appearance: none;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 12px;
    width: 12px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -2px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  &::-webkit-slider-runnable-track {
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;

    background: #ffffff;
    border-radius: 1.3px;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
  }
  &::-moz-range-track {
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background-color: #f2cfad;
    border-radius: 1.3px;
  }
  &::-moz-range-progress {
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background-color: #ffffff;
    border-radius: 1.3px;
  }
`;

export const SongInfo = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: left;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  /* identical to box height */

  color: #ffffff;
  margin-bottom: 30px;
`;
