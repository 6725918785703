import PropTypes from 'prop-types';

import styled from 'styled-components';

const ChampionImg = styled.img`
  display: block;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url(Aatrox_0.jpg);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
`;

export const ChampionLoadingImage = ({ champId }) => (
  <ChampionImg
    src={`http://ddragon.leagueoflegends.com/cdn/img/champion/loading/${champId}_0.jpg`}
  />
);

ChampionLoadingImage.propTypes = {
  champId: PropTypes.string.isRequired
};
