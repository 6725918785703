import PropTypes from 'prop-types';
import { useContext } from 'react';
import { SecondaryButton } from './styles';
import { FavoriteChampionsContext } from 'components/context/FavoriteChampionsContext';
import { FavoriteIcon } from 'static/svgs/favorite.svg';
import { RemoveIcon } from 'static/svgs/remove.svg';

export const ChampionFavToggleButton = ({ champId }) => {
  const { favorites, toggleFavoriteChampion } = useContext(
    FavoriteChampionsContext
  );
  const isChampionFav = (() => {
    if (favorites) return favorites.indexOf(champId) !== -1;
    return false;
  })();

  return (
    <SecondaryButton
      style={{ width: '45%' }}
      type="button"
      onClick={() => toggleFavoriteChampion(champId)}
    >
      {isChampionFav ? <RemoveIcon /> : <FavoriteIcon />}
      <span>{`${isChampionFav ? 'Remove from' : 'Add to'} Favorites`}</span>
    </SecondaryButton>
  );
};

ChampionFavToggleButton.propTypes = {
  champId: PropTypes.string.isRequired
};
