import PropTypes from 'prop-types';
import styled from 'styled-components';

import facebook from 'assets/facebook.svg';
import twitter from 'assets/tweet.svg';
import youtube from 'assets/youtube.svg';

const ShareBar = styled.div`
  position: absolute;
  z-index: 15;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ShareIcon = styled.a`
  display: flex;
  padding: 1.2rem 0.8rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 12px 0px 0px 12px;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 0.8rem;
  color: #242424;
  font-weight: 600;

  :not(:last-child) {
    margin-bottom: 1rem;
  }

  span {
    display: inline-block;
    margin-left: 0.3rem;
  }
`;
export const ShareSide = ({ videoUrl }) => (
  <ShareBar>
    {videoUrl && (
      <ShareIcon href={videoUrl} target="_blank" rel="noopener noreferrer">
        <img src={youtube} alt="youtube share" />
        <span>YouTube URL</span>
      </ShareIcon>
    )}
    <ShareIcon
      href={`https://www.facebook.com/sharer/sharer.php?quote=Check+out&u=${window.location.href}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={facebook} alt="facebook share" />
      <span>Share on facebook</span>
    </ShareIcon>
    <ShareIcon
      href={`https://twitter.com/intent/tweet?text=Check+out&url=${window.location.href}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={twitter} alt="twitter share" />
      <span>Share on twitter</span>
    </ShareIcon>
  </ShareBar>
);

ShareSide.defaultProps = {
  videoUrl: ''
};

ShareSide.propTypes = {
  videoUrl: PropTypes.string
};
