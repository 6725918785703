import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/utils';
import { VotingThunks } from 'store/voting/actions';
import { createSelectVotingSong } from 'store/voting/selectors';
import { ChampId, VideoId } from 'types';

// TODO: Consider allowing reverting and cancelling the vote

export const useVoting = (champId: ChampId, songId: VideoId) => {
  const selectVotingSong = useMemo(() => createSelectVotingSong(songId), [
    songId
  ]);

  const foundVotedSong = useAppSelector(selectVotingSong);

  const isVoted = !!foundVotedSong;

  const dispatch = useAppDispatch();

  const upvote = () => {
    if (isVoted) return;
    return dispatch(VotingThunks.upvoteSong({ champId, songId }));
  };

  const downvote = () => {
    if (isVoted) return;
    return dispatch(VotingThunks.downvoteSong({ champId, songId }));
  };

  return {
    isVoted,
    upvote,
    downvote
  };
};
