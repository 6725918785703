/* eslint-disable react/jsx-props-no-spreading */
export const RemoveIcon = props => (
  <svg fill="none" height={24} viewBox="0 0 24 24" width={24} {...props}>
    <path
      clipRule="evenodd"
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm2.59 6L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM4 12c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8-8 3.59-8 8z"
      fill="red"
      fillOpacity={0.6}
      fillRule="evenodd"
    />
  </svg>
);
