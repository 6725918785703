import { useState, useEffect } from 'react';
import { RiotChampion } from 'types/riot';

export const useChampionsData = () => {
  const [champions, setChampions] = useState<RiotChampion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const versionsEndPoint =
        'https://ddragon.leagueoflegends.com/api/versions.json';
      try {
        setIsLoading(true);
        const [lastVersion] = await (await fetch(versionsEndPoint)).json();

        const championsEndPoint = `https://ddragon.leagueoflegends.com/cdn/${lastVersion}/data/en_US/champion.json`;

        const { data: championsData } = await (
          await fetch(championsEndPoint)
        ).json();

        const championsIdsAndNames = (Object.values(
          championsData
        ) as RiotChampion[]).map(({ id, name, key, title }) => ({
          id,
          name,
          key,
          title
        }));

        setChampions(championsIdsAndNames);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
      }
    };
    fetchData();
  }, []);

  return { champions, isLoading, isError };
};
