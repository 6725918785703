import { createSlice } from '@reduxjs/toolkit';
import { VotingThunks } from './actions';
import { VotingState } from './types';

const initialState: VotingState = {
  songsIds: [],
  status: 'ready'
};

// could make improvements by seeing to ppl that they can upvote downvoted stuff and viceversa

const votingSlice = createSlice({
  name: 'voting',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(VotingThunks.upvoteSong.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(VotingThunks.upvoteSong.fulfilled, (state, action) => {
        state.songsIds.push(action.payload);
        state.status = 'ready';
      })
      .addCase(VotingThunks.upvoteSong.rejected, (state, action) => {
        state.status = 'ready';
      });

    builder
      .addCase(VotingThunks.downvoteSong.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(VotingThunks.downvoteSong.fulfilled, (state, action) => {
        state.songsIds.push(action.payload);
        state.status = 'ready';
      })
      .addCase(VotingThunks.downvoteSong.rejected, (state, action) => {
        state.status = 'ready';
      });
  }
});

const { reducer, actions } = votingSlice;

export { reducer as votingReducer };
export { actions as votingActions };
