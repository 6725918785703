import { createSelector } from 'reselect';
import { VideoId } from 'types';
import { RootState } from 'types/RootState';

const selectDomain = (state: RootState) => state.voting;

export const selectSongs = createSelector(
  [selectDomain],
  voting => voting.songsIds
);

export const createSelectVotingSong = (videoId: VideoId) =>
  createSelector([selectSongs], songsIds =>
    songsIds.find(id => id === videoId)
  );
