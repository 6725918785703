import styled from 'styled-components';

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const TextRectangle = styled.div<{ main?: boolean }>`
  width: 90%;
  padding: 40px 40px 38px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  text-align: left;
  background: ${({ main = false }) =>
    main ? 'rgba(225, 138, 51, 0.8)' : 'rgba(255, 255, 255, 0.8)'};
  border-radius: 12px;

  :hover {
    background: ${({ main = false }) =>
      main ? 'rgba(225, 138, 51, 1)' : 'rgba(255, 255, 255, 1)'};
  }

  svg {
    path {
      fill: ${({ main = false }) => (main ? '#ffffff' : '#E18A33')};
    }
  }

  div {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
  }

  color: ${({ main }) => (main ? '#ffffff' : '#E18A33')};
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;

    margin-right: 1rem;
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
  }

  margin-bottom: 40px;
`;

export const ControlsContainer = styled.div`
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
`;

export const SubmitSongForm = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
`;

export const Input = styled.input`
  padding: 1rem;
  border: 0.5px solid #333;
  border-radius: 6px;
  outline: none;
  flex: 3;
  margin: 0.5rem;
  &:focus {
    border: 0.5px solid #f49333;
  }
  &::placeholder {
    text-transform: capitalize;
  }
`;

export const Button = styled.button<{ isLoading: boolean }>`
  padding: 0.8rem 2rem;
  border: none;
  outline: none;
  background-color: #fff;
  color: #242424;
  text-transform: capitalize;
  border-radius: 6px;
  cursor: ${props => (props.isLoading ? 'progress' : 'pointer')};
  transition: all 0.2s;
  flex: 1;
  margin: 0.5rem;

  font-weight: 700;
  &:hover {
    background-color: ${props => (props.isLoading ? '#ccc' : '#f49333')};
    color: #fff;
  }
`;

export const ErrorMessage = styled.p`
  text-align: center;
  color: #f61c0d;
  font-weight: 700;
  margin: 1rem 0;
`;

export const SecondaryButton = styled.button`
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 22px;
  margin-top: 1rem;

  :hover {
    background: rgba(255, 255, 255);
  }

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;

    color: #242424;

    margin-left: 1rem;
  }
`;
