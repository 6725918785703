import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import YTPlayer from 'yt-player';
import {
  PlayerContainer,
  Icon,
  PlaylistControls,
  YoutubeEmbed,
  VolumeControls,
  Slider,
  SongControls,
  TimeControls,
  Text,
  SongInfo
} from './styles';
import PlayButton from 'static/svgs/play-button.svg';
import PauseButton from 'static/svgs/pause-button.svg';
import NextButton from 'static/svgs/next-button.svg';
import PreviousButton from 'static/svgs/previous-button.svg';
import VolumeIcon from 'static/svgs/volume-icon.svg';
import MuteIcon from 'static/svgs/mute-icon.svg';
import { secToMin } from 'utils/helpers';

const shouldAutoPlay = !(process.env.NODE_ENV === 'development');

export const YoutubePlayer = ({ videoId, prev, next }) => {
  // TODO: replace with react-youtube, should be ytplayer
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [player, setPlayer] = useState<any | null>(null);
  const [mute, setMute] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const [volume, setVolume] = useState(100);
  const [loadError] = useState(false); // would use later to add ability to report bugged unplayable vids
  const [videoData, setVideoData] = useState({ title: 'Loading' });

  useEffect(() => {
    if (player === null && !loadError) {
      setPlayer(
        new YTPlayer('#yt', {
          width: '0',
          height: '0'
        })
      );
    }

    if (player && !loadError) {
      player.load(videoId, shouldAutoPlay);
      player.setPlaybackQuality('small');

      const handlePause = () => {
        setPlaying(false);
      };
      const handleLoaded = () => {
        setDuration(player.getDuration());
        if (player.getState() === 'playing') setPlaying(true);
        // eslint-disable-next-line no-underscore-dangle
        const vidPlayerData = player._player.getVideoData();
        if (vidPlayerData && vidPlayerData.title) setVideoData(vidPlayerData);
        else setVideoData({ title: 'Loading' });
      };
      const handleTimeUpdate = seconds => {
        setTime(seconds);
      };

      player.on('unstarted', handleLoaded); // in case it doesnt autoplay on mobile we want to show the vid title
      player.on('playing', handleLoaded);
      player.on('paused', handlePause);
      player.on('timeupdate', handleTimeUpdate);
      player.on('ended', next);
      player.on('unplayable', next); // maybe we should report that to us ??

      return () => {
        player.removeListener('unstarted', handleLoaded); // in case it doesnt autoplay on mobile we want to show the vid title
        player.removeListener('playing', handleLoaded);
        player.removeListener('paused', handlePause);
        player.removeListener('timeupdate', handleTimeUpdate);
        player.removeListener('ended', next);
        player.removeListener('unplayable', next);
      };
    }
    return () => {};
  }, [loadError, next, player, videoId]);

  const handlePlayToggle = () => {
    switch (player.getState()) {
      case 'playing':
        player.pause();
        break;
      case 'unstarted':
      case 'paused':
      case 'cued':
        player.play();
        break;
      default:
    }
  };

  const handleVolumeChange = ({ target: { value } }) => {
    setVolume(value);
    if (player) {
      if (player.isMuted()) {
        player.unMute();
        setMute(false);
      }
      player.setVolume(value);
    }
  };

  const toggleMute = () => {
    if (player.isMuted()) {
      player.unMute();
      setMute(false);
    } else {
      player.mute();
      setMute(true);
    }
  };

  const handleSeek = ({ target: { value } }) => {
    player.seek(value);
    setTime(value);
  };

  return (
    <>
      <SongInfo>{videoData && videoData.title}</SongInfo>
      <PlayerContainer>
        <YoutubeEmbed id="yt" />
        <PlaylistControls>
          <Icon src={PreviousButton} onClick={prev} />
          <Icon
            src={playing ? PauseButton : PlayButton}
            onClick={handlePlayToggle}
          />
          <Icon src={NextButton} onClick={next} />
        </PlaylistControls>
        <SongControls>
          <TimeControls>
            <Text>{secToMin(time)}</Text>
            <Slider
              value={time}
              onChange={handleSeek}
              type="range"
              min="0"
              max={duration}
              step="1"
            />
            <Text>{secToMin(duration)}</Text>
          </TimeControls>
          <VolumeControls>
            <Icon src={mute ? MuteIcon : VolumeIcon} onClick={toggleMute} />
            <Slider
              value={volume}
              onChange={handleVolumeChange}
              type="range"
              min="0"
              max="100"
              step="1"
            />
          </VolumeControls>
        </SongControls>
      </PlayerContainer>
    </>
  );
};

YoutubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired
};
