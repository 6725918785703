import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Loading } from 'components/common';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import { App } from './App';
import './index.css';
const history = createBrowserHistory();

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={
      'An error has occurred, sorry about that, we recieved it and working on it'
    }
  >
    <ReduxProvider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router history={history}>
          <App />
        </Router>
      </PersistGate>
    </ReduxProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
