import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { fire } from 'fire';
import { capitalizeFirstLetter } from 'utils/helpers';
import { ChampionFavToggleButton } from './ChampionFavToggleButton';
import { ShareSide, YoutubePlayer } from 'components/common';
import {
  Button,
  ErrorMessage,
  Input,
  SubmitSongForm,
  SecondaryButton
} from './styles';

import { SwitchIcon } from 'static/svgs/switch.svg';
import { AddIcon } from 'static/svgs/add.svg';
import { LikeIcon } from 'static/svgs/like.svg';
import { DislikeIcon } from 'static/svgs/dislike.svg';
import { useVoting } from 'components/hooks/useVoting';

export const PlaylistPlayer = ({ mainPlaylist, votingPlaylist, id }) => {
  const { id: championId, playlistType } = useParams<{
    id: string;
    playlistType: string;
  }>();
  const history = useHistory();
  const [playlist, setPlaylist] = useState<string[]>([]);
  useEffect(() => {
    if (playlistType === 'main' && mainPlaylist) setPlaylist(mainPlaylist);
    if (playlistType === 'voting' && votingPlaylist)
      setPlaylist(Object.keys(votingPlaylist));
  }, [playlistType, mainPlaylist, votingPlaylist]);

  const [queuePosition, setQueuePosition] = useState(0);

  const next = useCallback(
    () => setQueuePosition(prevQueue => (prevQueue + 1) % playlist.length),
    [playlist.length]
  );
  const prev = useCallback(
    () =>
      setQueuePosition(
        prevQueue => (prevQueue - 1 + playlist.length) % playlist.length
      ),
    [playlist.length]
  );

  const [videoId, setVideoId] = useState('');
  const { isVoted, downvote, upvote } = useVoting(championId, videoId);

  useEffect(() => {
    if (playlist) setVideoId(playlist[queuePosition] || '');
  }, [playlist, queuePosition]);

  const getAlternateQueue = () => {
    if (playlistType === 'main') return 'voting';
    if (playlistType === 'voting') return 'main';
    return '';
  };

  const [shouldShowForm, setShouldShowForm] = useState(false);
  const [songURL, setSongURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitSong = async () => {
    const champsData = await fire.getChampData(championId);
    if (songURL) {
      const regex = new RegExp('^(https?://)?(www.youtube.com|youtu.?be)/.+$');
      const isValid = regex.test(songURL);

      if (isValid) {
        const newVideoId = songURL.slice(
          songURL.indexOf('v=') + 2,
          songURL.indexOf('v=') + 13 // YouTube video ID consists of 11 characters
        );

        if (!champsData?.votingPlaylist[newVideoId]) {
          try {
            setIsLoading(true);
            await fire.submitSong(newVideoId, championId);
            setIsLoading(false);
            setSongURL('');
            setErrorMessage('');
          } catch (error) {
            history.push('/500');
            // console.log(error);
          }
        } else {
          setErrorMessage('This song is already added');
        }
      } else {
        setErrorMessage('This is not a valid YouTube URL');
      }
    }
  };
  // console.log(videoId);
  return (
    <>
      <YoutubePlayer videoId={videoId} prev={prev} next={next} />
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        {playlistType === 'voting' && !isVoted && (
          <>
            <SecondaryButton style={{ width: '20%' }} onClick={upvote}>
              <LikeIcon />
            </SecondaryButton>
            <SecondaryButton style={{ width: '20%' }} onClick={downvote}>
              <DislikeIcon />
            </SecondaryButton>
          </>
        )}
        <SecondaryButton
          style={{ width: '45%' }}
          onClick={() => {
            history.push(`/lol/${championId}/${getAlternateQueue()}`);
          }}
        >
          <SwitchIcon />
          <span>
            {`Switch to ${capitalizeFirstLetter(getAlternateQueue())} Playlist`}
          </span>
        </SecondaryButton>
        {playlistType === 'voting' ? (
          <SecondaryButton
            style={{ width: '45%' }}
            onClick={() => setShouldShowForm(prevShouldShow => !prevShouldShow)}
          >
            <AddIcon />
            <span>Submit A Song</span>
          </SecondaryButton>
        ) : (
          ''
        )}
        {shouldShowForm ? (
          <SubmitSongForm>
            <Input
              type="text"
              placeholder="type in the song URL"
              value={songURL}
              onChange={({ target: { value } }) => setSongURL(value)}
            />
            <Button
              type="submit"
              onClick={submitSong}
              disabled={isLoading}
              isLoading={isLoading}
            >
              submit
            </Button>
          </SubmitSongForm>
        ) : (
          ''
        )}
        <ChampionFavToggleButton champId={id} />
        <ErrorMessage> {errorMessage} </ErrorMessage>
      </div>
      <ShareSide videoUrl={videoId && `https://youtu.be/${videoId}`} />
    </>
  );
};

PlaylistPlayer.propTypes = {
  mainPlaylist: PropTypes.arrayOf(PropTypes.string).isRequired,
  votingPlaylist: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired
};
