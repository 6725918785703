import { useHistory } from 'react-router-dom';
import './RandomButton.css';
import PropTypes from 'prop-types';
import randomIcon from 'assets/random_icon.png';
import { getRandomNumber } from 'utils/helpers';

export const RandomButton = ({ dataSource: champions }) => {
  const history = useHistory();

  function handleClick() {
    const rand = getRandomNumber(0, champions.length - 1);
    const champId = champions[rand].id.toString();

    history.push(`/lol/${champId}`);
  }

  return (
    <button
      type="button"
      className="random-btn"
      onClick={() => {
        handleClick();
      }}
    >
      <img src={randomIcon} alt="Random Icon" />
    </button>
  );
};

RandomButton.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired
};
