import { useChampionsData } from 'components/hooks';
import PropTypes from 'prop-types';
import { AutoComplete, Loading, RandomButton } from 'components/common';
import {
  AppName,
  FixedText,
  LandingWrapper,
  MainHeading,
  Paragraph,
  RandomText
} from './styles';

export const Landing = ({ patchVersion }) => {
  // TODO: Redux.. please
  const { champions, isLoading, isError } = useChampionsData();

  if (isError) return <div>error</div>;
  if (isLoading) return <Loading message="Getting Champions" />;
  return (
    <LandingWrapper>
      <MainHeading>
        Welcome to <AppName>Mood.gg</AppName> for League of legends
      </MainHeading>
      <Paragraph>
        A site that plays a themed playlist for the specific champion you are
        playing
      </Paragraph>
      <AutoComplete
        dataSource={champions}
        patchVersion={patchVersion}
        resultCount={4}
      />
      <FixedText>
        <RandomText>Or click</RandomText>
        <RandomButton dataSource={champions} />
        <RandomText>to go random</RandomText>
      </FixedText>
    </LandingWrapper>
  );
};

Landing.defaultProps = {
  patchVersion: '10.1.1'
};

Landing.propTypes = {
  patchVersion: PropTypes.string
};
