import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ChampionIcon } from 'components/common/ChampionIcon';
import {
  SearchBox,
  InputWrapper,
  Input,
  Image,
  ChampionsList,
  SingleChampion,
  ChampionName,
  DesiredName
} from './styles';
import SearchIcon from 'assets/search.svg';

export const AutoComplete = ({
  dataSource: champions,
  patchVersion,
  resultCount
}) => {
  const history = useHistory();
  const [desiredChampion, setDesiredChampion] = useState('');
  const [suggestedChampions, setSuggestedChampions] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    let matchedChampions = [];
    if (desiredChampion.length > 0) {
      matchedChampions = champions
        .sort()
        .filter(
          ({ name, id }) =>
            name.toLowerCase().startsWith(desiredChampion.toLowerCase()) ||
            id.toLowerCase().startsWith(desiredChampion.toLowerCase())
        );
    }
    setSuggestedChampions(matchedChampions.slice(0, resultCount));
  }, [champions, desiredChampion, resultCount]);

  const handleInputChange = ({ target: { value } }) => {
    setDesiredChampion(value);
  };

  const handleChampionClick = championId => {
    history.push(`/lol/${championId}`);
  };

  const [selectedChampion, setSelectedChampion] = useState(0);

  const handleAutoCompleteControls = e => {
    if (e.key === 'ArrowUp') {
      setSelectedChampion(
        (selectedChampion - 1 + suggestedChampions.length) %
          suggestedChampions.length
      );
    }
    if (e.key === 'ArrowDown') {
      setSelectedChampion((selectedChampion + 1) % suggestedChampions.length);
    }
    if (e.key === 'Enter' && suggestedChampions[selectedChampion]) {
      history.push(`/lol/${suggestedChampions[selectedChampion].id}`);
    }
  };

  return (
    <SearchBox>
      <InputWrapper>
        <Input
          type="text"
          placeholder="Type your champion's name"
          value={desiredChampion}
          onChange={handleInputChange}
          onKeyDown={handleAutoCompleteControls}
        />
        <Image src={SearchIcon} alt="search" />
      </InputWrapper>
      {desiredChampion.length > 0 && (
        <ChampionsList>
          {suggestedChampions.map(({ name, id }, index) => (
            <SingleChampion
              key={id}
              selected={index === selectedChampion}
              onClick={() => handleChampionClick(id)}
            >
              <ChampionIcon patchVersion={patchVersion} champId={id} size={2} />
              <ChampionName>
                <DesiredName>
                  {`${name.slice(0, desiredChampion.length)}`}
                </DesiredName>
                {`${name.slice(desiredChampion.length)}`}
              </ChampionName>
            </SingleChampion>
          ))}
        </ChampionsList>
      )}
    </SearchBox>
  );
};

AutoComplete.defaultProps = {
  patchVersion: '10.1.1'
};

AutoComplete.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  patchVersion: PropTypes.string,
  resultCount: PropTypes.number.isRequired
};
