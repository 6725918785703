import { favouritesActions } from 'store/favourites';
import { selectFavourites } from 'store/favourites/selectors';
import { useAppDispatch, useAppSelector } from 'store/utils';
import { ChampId } from 'types';

export const useFavChamps = () => {
  const favorites = useAppSelector(selectFavourites);
  const dispatch = useAppDispatch();

  const toggleFavoriteChampion = (champId: ChampId) => {
    console.log(favorites.indexOf(champId) === -1);
    if (favorites.indexOf(champId) === -1)
      dispatch(favouritesActions.addToFav(champId));
    else dispatch(favouritesActions.removeFav(champId));
  };

  return { favorites, toggleFavoriteChampion };
};
