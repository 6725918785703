/* eslint-disable react/jsx-props-no-spreading */
export const LikeIcon = props => (
  <svg fill="none" height={26} viewBox="0 0 28 26" width={28} {...props}>
    <path
      clipRule="evenodd"
      d="M10.25 25.5H21.5a2.483 2.483 0 002.3-1.525l3.775-8.813a2.47 2.47 0 00.175-.912v-2.5c0-1.375-1.125-2.5-2.5-2.5h-7.887l1.187-5.713.037-.4c0-.512-.212-.987-.55-1.325L16.713.5 8.475 8.738A2.484 2.484 0 007.75 10.5V23c0 1.375 1.125 2.5 2.5 2.5zm0-15l5.425-5.425L14 11.75h11.25v2.5L21.5 23H10.25V10.5zm-5 0h-5v15h5v-15z"
      fill="#E08932"
      fillRule="evenodd"
    />
  </svg>
);
